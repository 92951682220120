<script setup lang="ts">
import ButtonHighlightSimple from "@/components/buttons/ButtonHighlightSimple.vue";

import { useFormulaFormStore } from "@/stores/formulaFormStore"
import {useRouter} from "vue-router";

const router = useRouter();
const formulaFormStore = useFormulaFormStore()


function navigateToFormula(type: "Original" | "Ceratotomia Radial" | "Refrativa Miopia" | "Refrativa Hipermetropia" | undefined)
{
    formulaFormStore.formulaForm.type = type
    router.push({'name': 'formula'})
}

</script>

<template>
    <div class="page--with-limit">
      <div class="title-container">
        <h1>{{ $t("index.title_1") }} <b>Lucena</b> {{ $t("index.title_2") }}</h1>
      </div>

      <div class="buttons-container">
        <ButtonHighlightSimple @click="navigateToFormula('Original')">
          {{ $t("index.buttons.original") }}
          <p>{{ $t("index.buttons.original_sub") }}</p>
        </ButtonHighlightSimple>

        <ButtonHighlightSimple @click="navigateToFormula('Refrativa Miopia')">
          {{ $t("index.buttons.miopia") }}
        </ButtonHighlightSimple>

        <ButtonHighlightSimple @click="navigateToFormula('Refrativa Hipermetropia')">
          {{ $t("index.buttons.hipermetropia") }}
        </ButtonHighlightSimple>

        <ButtonHighlightSimple @click="navigateToFormula('Ceratotomia Radial')">
          {{ $t("index.buttons.ceratotomia") }}
        </ButtonHighlightSimple>
      </div>

    </div>
</template>

<style scoped lang="sass">
@use "../assets/colors/colors"

$break-layout: 1130px

.form-data-basic
    &__title
        display: none

    &__content
        display: flex
        flex-direction: column
        gap: 0.6em

    @media (min-width: 700px)
        &__content
            flex-direction: row
            gap: 3.70%

        &__field-surge
            width: 20%

        &__field-patient
            width: 59.6%

        &__field-year
            width: 13%

.form-data-parameters
  padding-top: 5.6rem
  display: flex
  flex-direction: column
  gap: 4em

  @media (min-width: $break-layout)
    flex-direction: row
    gap: 3.2em

  &__od
    color: colors.$tertiary
    flex-grow: 1

  &__os
    color: colors.$quaternary
    flex-grow: 1

.title-container
  position: relative
  padding: 15px
  border-radius: 20px
  border: 3px solid colors.$primary
  margin: 60px auto
  width: fit-content

  h1
    text-align: center
    font-size: 3em
    margin: 0 30px

  &::before
    content: ''
    display: inline-block
    width: 50px
    height: 50px
    position: absolute
    left: -25px
    top: calc(50% - 25px)
    -moz-border-radius: 25px
    -webkit-border-radius: 25px
    border-radius: 25px
    background-color: colors.$primary

.buttons-container
  display: flex
  justify-content: center
  justify-items: center
  flex-wrap: wrap
  align-content: center

  button
    width: 200px
    margin: 10px

    p
      font-size: 0.6em
      margin-top: 5px
      font-weight: bold

.formula-form
  &__footer
    padding-top: 7.71%
    text-align: center

</style>
