<template>
    <div class="card">
        <slot></slot>
    </div>
</template>

<style scoped lang="sass">
@use "../../assets/colors/colors"
.card
  border-radius: 4px
  background-color: colors.$secondary-contrast-strong

  @media screen
    box-shadow: 2px 0 4px rgba(0,0,0,0.25)

  @media print
    border: 1px solid #000000
</style>