export class ModelError extends Error
{
    private readonly code: string

    constructor(message: string, code: string) {
        super(message)
        this.code = code
    }

    public getCode(): string
    {
        return this.code
    }
}