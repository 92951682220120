import type AuthenticatedUser from "@/models/AuthenticatedUser"
import { defineStore } from "pinia"
import { ref } from "vue"

const useAuthenticatedUserStore = defineStore('authenticatedUserStore', () => 
{
    const authenticatedUser = ref<AuthenticatedUser | null>(null)

    function save(authenticatedUserNew: AuthenticatedUser): void
    {
        authenticatedUser.value = authenticatedUserNew
    }

    function clear(): void
    {
        authenticatedUser.value = null
    }

    return {
        save, 
        clear,
        authenticatedUser,
    }
})

export {
    useAuthenticatedUserStore
}