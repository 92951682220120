import { computed, reactive, ref } from 'vue'
import { defineStore } from 'pinia'
import type { CalculatorResult, CalculatorResultData } from '@/models/CalculatorResult'

export const useFormulaResultStore = defineStore('formulaResultStore', () => {

    const withResult = ref(false)
    const patientName = ref('')
    const patientYear = ref(0)
    const surgeon = ref('')
    const dateTime = ref(new Date)
    const od = ref([] as CalculatorResultData[])
    const os = ref([] as CalculatorResultData[])
    const uuid = ref('')
    const isSaved = ref(false)

    function save(result: CalculatorResult)
    {
        patientName.value = result.patient.name ?? ''
        patientYear.value = result.patient.year ?? 0
        surgeon.value = result.surgeon ?? ''
        dateTime.value = result.dateTime
        od.value = result.od
        os.value = result.os
        uuid.value = result.uuid
        withResult.value = true
    }

    const result = computed(() => {
        if(! withResult.value) {
            return undefined
        }

        return {
            uuid: uuid.value,
            patient: {
                name: patientName.value,
                year: patientYear.value
            },
            dateTime: dateTime.value,
            surgeon: surgeon.value,
            od: od.value,
            os: os.value

        } as CalculatorResult
    })

    return {
        save,
        isSaved,
        result
    }
})