export function calculeAxis(axis: number): number
{
    return axis <= 90
        ? axis + 90
        : axis - 90
}

export function calculeLt(year: number, acd: number): number
{
    return (year * 0.007 + acd * -0.653) + 6.258
}