import axios from "axios";
import type { AxiosInstance } from "axios";
import { useAuthenticatedUserStore } from "@/stores/authenticatedUserStore";

const httpClient: AxiosInstance = axios.create({
    baseURL: import.meta.env.VITE_API_URL,
    headers: {
        "Accept": "application/json",
        "Content-type": "application/json",
    },
})

httpClient.interceptors.request.use((config) => {
    const contaStore = useAuthenticatedUserStore()

    if(contaStore.authenticatedUser) {
        config.headers.Authorization = `Bearer ${contaStore.authenticatedUser.token}`
    }

    return config
})

export default httpClient;