export enum TypeMenssage {
    SUCESSO,
    ERRO,
    AVISO
}

export default interface Menssage {
    title: string,
    description: string,
    type: TypeMenssage,
    id: number
}