import { createApp } from 'vue'
import { createPinia } from 'pinia'
import * as Sentry from '@sentry/vue'
import App from './App.vue'
import router from './router'

import { createI18n } from 'vue-i18n'

import messagesEn from './assets/i18n/en.json'
import messagesPtBr from './assets/i18n/pt-br.json'

const i18n = createI18n({
    legacy: false,
    locale: 'pt-br', // Defina o locale padrão
    messages: {
        en: messagesEn, // Mensagens em inglês
        'pt-br': messagesPtBr // Mensagens em português brasileiro
    }
})
const app = createApp(App)
const pinea = createPinia()

if(import.meta.env.PROD){
    Sentry.init({
        app,
        dsn: import.meta.env.VITE_SENTRY_DSN,
        integrations: [
        new Sentry.BrowserTracing({
            // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
            tracePropagationTargets: ["localhost", /^https:\/\/formula\.escolacearenseoftalmologia\.com\.br/],
        }),
        new Sentry.Replay({
            maskAllText: false,
            blockAllMedia: false,
        }),
        ],
        // Performance Monitoring
        tracesSampleRate: 1.0, //  Capture 100% of the transactions
        // Session Replay
        replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
        replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    })
}

app.use(pinea)
app.use(router)
app.use(i18n as any)

app.mount('#app')
