<template>
    <button class="button button-simple" type="button">
        <slot></slot>
    </button>
</template>

<style scoped lang="sass">
@use "../../assets/colors/colors"

.button
  padding: 0.9ex 2ex
  font-size: 2.4em
  border: none
  border-radius: 4px
  transition: transform 0.1s
  cursor: pointer
  margin: 5px

  &:active
    transform: scale(0.9)
    transition: transform 0.1s

.button-simple
  background-color: colors.$secondary
  color: colors.$secondary-contrast
</style>