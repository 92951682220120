<script setup lang="ts">
import FieldLensSelect from '../fields/FieldLensSelect.vue';
import FieldOcularAConstant from '../fields/FieldOcularAConstant.vue';
import {findLensByNumber, findLensModel} from "@/stores/lensModelCollection";

const props = defineProps({
    title: {
        type: String,
        required: false
    },
    required: {
        type: Boolean,
        required: false,
        default: false
    },
    aConstant: {
        type: Number,
        required: false,
        default: 0
    },
    idLens: {
      type: Number,
      required: false,
      default: 0
    }
})
const emit = defineEmits(['update:aConstant', 'update:idLens'])

const toEmitAConstant = (newValue: number) => {
  emit('update:aConstant', newValue);
  if(findLensByNumber(newValue)) {
    if (findLensModel(Number(props.idLens))?.number !== newValue)
      emit('update:idLens', findLensByNumber(newValue)?.id)
  } else {
    emit('update:idLens', 0)
  }
}

const toEmitLens = (newValue: number) => {
  if(newValue == null) {
    emit('update:idLens', 0)
    emit('update:aConstant', 0)
    return
  }
  emit('update:idLens', newValue)
  if(findLensModel(newValue)) {
    emit('update:aConstant', findLensModel(newValue)?.number)
  }
}

</script>

<template>
    <div class="box">
        <h2 class="box__title">{{ title }}</h2>
        <div class="container1">
            <FieldOcularAConstant 
                label="A-Constant"
                info="(117.00 - 119.90)"
                :model-value="aConstant"
                :required="required"
                @change:aConstant="toEmitAConstant"
            />
        </div>

        <div class="container2">
            <FieldLensSelect 
                :label="$t('formula.form.model')"
                :model-value="idLens"
                @change:idLens="toEmitLens"
            />
        </div>
    </div>
</template>

<style scoped lang="sass">
@use "../../assets/colors/colors"
@use "../../assets/fonts/fonts"

$break-layout: 490px

.box
    border: 1px solid colors.$border
    display: flex
    flex-direction: column
    border-radius: 0.4em
    padding-bottom: 2em
    padding-left: 2em
    padding-right: 2em
    padding-top: 0.8em
    flex-grow: 1

    &__title
        font-size: 2em
        font-weight: 500
        color: colors.$secondary

.container1
  padding-top: 2.4em
  padding-bottom: 1.1em

.container2
  color: colors.$secondary

</style>