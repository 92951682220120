<script setup lang="ts">
import PeopleCard from "@/components/cards/PeopleCard.vue"
import NavbarFormula from "@/components/navbar/NavbarFormula.vue";
import {useFormulaFormStore} from "@/stores/formulaFormStore";
import TextAbout from "@/components/texts/TextAbout.vue";
import router from "@/router";


const formulaFormStore = useFormulaFormStore()

if (formulaFormStore.data.type === undefined) {
  router.replace("/");
}

</script>

<template>
    <div class="page--with-limit">
        <NavbarFormula></NavbarFormula>
        <div class="about">
            <div class="about__content">
                <div class="text">
                    <div class="text__item">
                      <TextAbout :type="formulaFormStore.data.type"></TextAbout>
                    </div>
                </div>
            </div>
            <div class="about__author">
                <PeopleCard/>
            </div>
        </div>
    </div>
</template>

<style lang="sass">

.page--with-limit
  position: relative

.about
    max-width: 600px
    margin: 0 auto

    &__select
        margin-left: auto
        margin-bottom: 1.4em
        width: fit-content

        &-field
            font-size: 1.4em

.text
  &__item
    & h2
      font-size: 1.8em
      font-weight: bold
      margin-bottom: 2.3em

    & p
      font-size: 1.4em
      margin-bottom: 2.1em
</style>