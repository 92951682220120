import { createRouter, createWebHistory } from 'vue-router'
import Index from '@/pages/Index.vue'
import About from "@/pages/About.vue"
import Result from '@/pages/Result.vue'
import Formula from "@/pages/Formula.vue";

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: '/',
      name: 'index',
      component: Index
    },
    {
      path: '/formula',
      name: 'formula',
      component: Formula
    },
    {
      path: '/about',
      name: 'about',
      component: About
    },
    {
      path: '/result',
      name: 'result',
      component: Result
    }
  ]
})

export default router
