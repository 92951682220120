<template>
  <button class="button button-highlight">
    <slot></slot>
  </button>
</template>

<style scoped lang="sass">
@use "../../assets/colors/colors"
.button
  padding: 0.9ex 2ex
  font-size: 2em
  border: 0.5px solid #cccccc90
  border-radius: 10px
  transition: transform 0.1s
  cursor: pointer

  &:active
    transform: scale(0.9)
    transition: transform 0.1s

.button-highlight
  background-color: colors.$fifth-light
  color: colors.$fifth-contrast
  font-weight: bold
  box-shadow: 1px 1px 1px 1px #00000080

  &:hover
    transform: scale(1.05)
    transition: transform 0.1s

  &:active
    transform: scale(0.9)
    transition: transform 0.1s

</style>