<template>
    <button class="button">
        <slot></slot>
    </button>
</template>

<style scoped lang="sass">
@use "../../assets/colors/colors"

.button
    border: none
    display: flex
    align-items: center
    fill: colors.$secondary
    width: 2.5em
    position: absolute
    right: 0.5em
    top: 0.4em
    cursor: pointer   

</style>