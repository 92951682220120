<template>
    <div class="footer">
        <p class="footer__text">{{$t('result.card.footer')}}</p>
    </div>
</template>

<style scoped lang="sass">
@use "../../assets/colors/colors"

.footer
    &__text
        font-size: 1.2em
        font-weight: 500

        @media print
          font-size: 1.7em
</style>