const monthsName = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'Setember',
    'October',
    'November',
    'December'
]

const monthsNamePtBR = [
    'Janeiro',
    'Fevereiro',
    'Março',
    'Abril',
    'Maio',
    'Junho',
    'Julho',
    'Agosto',
    'Setembro',
    'Outubro',
    'Novembro',
    'Dezembro'
]

export function monthName(numberMonth: number, locale = 'pt-br'): string
{
    if (locale === 'pt-br')
        return monthsNamePtBR[numberMonth] ?? 'Undefined'
    return monthsName[numberMonth] ?? 'Undefined'
}