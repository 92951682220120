<template>
  <h2>{{$t("about." + type + ".title")}}</h2>

  <p>{{$t("about." + type + ".p1")}}</p>
  <p>{{$t("about." + type + ".p2")}}</p>
  <p>{{$t("about." + type + ".p3")}}</p>
  <p>{{$t("about." + type + ".p4")}}</p>
  <p>{{$t("about." + type + ".p5")}}</p>
  <p>{{$t("about." + type + ".p6")}}</p>
</template>

<script lang="ts">

export default {
  props: {
    type: String
  }
}
</script>