<script setup lang="ts">
import FieldEmail from '../fields/FieldEmail.vue';
import FieldPassword from '../fields/FieldPassword.vue';
import ButtonHighlight from '../buttons/ButtonHighlight.vue';
import ButtonSimple from '../buttons/ButtonSimple.vue';
import { AuthorizationHttp } from '@/http/authorizationHttp'
import { useAuthenticatedUserStore } from '@/stores/authenticatedUserStore';
import { ref } from 'vue';
import type AuthenticatedUser from '@/models/AuthenticatedUser';
import { useMenssageStore } from '@/stores/menssage';

const authenticatedUserStore = useAuthenticatedUserStore()
const messageStore = useMenssageStore()

const emits = defineEmits([
    'cancelar',
    'authenticated'
])

const errorMessage = ref('')
const user = ref('')
const password = ref('')

function authenticate(): void
{
    if (! validate()) {
        return
    }

    AuthorizationHttp.authenticate({
        email: user.value,
        password: password.value
    }).then((authenticatedUser: AuthenticatedUser) => {
        authenticatedUserStore.save(authenticatedUser)
        messageStore.addSuccess(
            'User successfully authenticated',
            'It is now possible to save the formula result'
        )
        emits('authenticated', authenticatedUser)
    }).catch((e) => {
        messageStore.addError(
            'Error when trying to authenticate',
            'Please confirm your access details and try again'
        )
    })
}

function validate(): boolean
{
    if (user.value.length < 5) {
        errorMessage.value = 'E-mail invalid'
        return false
    }

    if (password.value.length < 3) {
        errorMessage.value = 'Password invalid'
        return false
    }

    errorMessage.value = ''
    return true
}

</script>

<template>
    <div class="container"></div>
    <div class="login">
        <div class="login__card">
            <div v-show="errorMessage" class="login__caixa">
                <p class="login__title">{{ errorMessage }}</p>
            </div>

            <div class="login__fields">
                <div>
                    <FieldEmail label="E-mail:" v-model="user"/>
                </div>
                <div>
                    <FieldPassword label="Password:" v-model="password"/>
                </div>
            </div>

            <div class="login__buttons">
                <ButtonSimple @click="$emit('cancelar')">Cancelar</ButtonSimple>
                <ButtonHighlight @click="authenticate">Confirmar</ButtonHighlight>
            </div>
        </div>
    </div>
</template>

<style scoped lang="sass">
@use "../../assets/colors/colors"

.container
    position: fixed
    left: 0
    bottom: 0
    right: 0
    top: 0
    background-color: colors.$login-bottom
    opacity: 0.5
    z-index: 100

.login
    position: fixed
    left: 0
    top: 0
    display: flex
    width: 100vw
    height: 100vh
    justify-content: center
    align-items: center
    z-index: 150

    &__title
        font-size: 1.6em
        width: 40%
        display: block
        text-align: center
        padding-top: 0.3em
        padding-bottom: 0.3em
        background-color: #831717
        color: #f9f2f2
        border-radius: 2px
        opacity: 1
        transition-property: bottom,opacity
        transition-delay: .1s
        transition-duration: 1s

    &__fields
        display: flex
        flex-direction: column
        gap: 2em

    &__caixa
        display: flex
        justify-content: center

    &__card
        box-shadow: 2px 0 4px rgba(0, 0, 0, 0.25)
        border-radius: 4px
        background-color: #FFFFFF
        padding: 2em
        display: flex
        flex-direction: column
        gap: 2em

    &__buttons
        display: flex
        gap: 4em
        padding-top: 1em
        justify-content: center

</style>