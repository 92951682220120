<script setup lang="ts">

const languageEnglish = {
  value: 'en',
  title: 'English'
}
const languagePortuguese = {
  value: 'pt-br',
  title: 'Português'
}

const languages = [
  languageEnglish,
  languagePortuguese
]

</script>

<template>
    <header class="header">
        <div class="header__content">
            <div class="header__identification">
                <img class="header__logo" src="@/assets/images/lucena-formula-v4.png" width="350" height="97" alt="Escola Cearense de Oftalomologia" @click="$router.push('/')">
            </div>
            <div class="locale-changer">
              <label for="language">{{$t("header.select.label")}}: </label>
              <select class="field-select__input" id="language" v-model="$i18n.locale">
                <option v-for="(lang, i) in languages" :key="`Lang${i}`" :value="lang.value">
                  {{ lang.title }}
                </option>
              </select>
            </div>
        </div>
    </header>
</template>

<style scoped lang="sass">
@use "../../assets/colors/colors"
@use "../../assets/fonts/fonts"

.menu-tabs
  &__container
    list-style: none
    margin: 0
    padding: 0

    @media screen
      display: flex

    @media print
      display: none

  &__button
    display: block
    border: none
    padding: 0.95em 1.2em
    border-bottom: solid 1px colors.$secondary
    background: none
    cursor: pointer

    &--active
      @extend .menu-tabs__button
      border-bottom-color: colors.$primary
      color: colors.$primary

.header
  font-size: 3.2em
  background-color: colors.$primary-and-secundary-contrast
  border-bottom: solid 1px colors.$secondary-contrast
  padding: 0 5%

  @media print
    background: colors.$primary-contrast
    padding: 0

  &__content
    display: flex
    justify-content: space-between
    flex-direction: column
    align-items: center
    width: 100%
    margin: 0 auto
    box-sizing: border-box

    @media print, (min-width: 620px)
      flex-direction: row
      align-items: flex-end

  &__identification
    display: flex
    align-items: center
    gap: 0.75em
    padding-top: 0.5em
    padding-bottom: 0.5em

  &__logo
    width: 301px
    max-width: 100%
    height: auto
    cursor: pointer

    @media print
        width: 150px

  &__title
    margin: 0
    font-size: inherit
    cursor: pointer
    @include fonts.semiBold

    @media screen
      color: colors.$primary

    @media print
      color: colors.$print

  &__menu
    display: flex

.locale-changer
  font-size: 14px
  font-weight: bold
  padding-bottom: 20px
  .field-select__label
      padding-right: 0.2em
      font-size: 1em

  .field-select__input
    border: none
    background: none
    color: inherit

  @media print
    display: none
</style>