<script setup lang="ts">
import { RouterView } from 'vue-router'
import HeaderMain from "@/components/layouts/HeaderMain.vue"
import CardMessage from './components/messages/CardMessage.vue';
import MenssageSuccess from './components/messages/MenssageSuccess.vue';
import MenssageError from './components/messages/MenssageError.vue';
import MenssageWarning from './components/messages/MenssageWarning.vue';
import { useMenssageStore } from './stores/menssage';
import { computed } from 'vue';
import { TypeMenssage } from './models/Menssage';

const store = useMenssageStore()
const menssageList = computed(() => store.menssageList)

</script>

<template>
    <HeaderMain/>
    <CardMessage>
        <TransitionGroup name="list">
            <template v-for="menssage in menssageList" :key="menssage.id">
                <MenssageSuccess v-if="menssage.type == TypeMenssage.SUCESSO" :title="menssage.title" :description="menssage.description" :id="menssage.id"/>
                <MenssageError v-if="menssage.type == TypeMenssage.ERRO" :title="menssage.title" :description="menssage.description" :id="menssage.id"/>
                <MenssageWarning v-if="menssage.type == TypeMenssage.AVISO" :title="menssage.title" :description="menssage.description" :id="menssage.id"/>
            </template>
        </TransitionGroup>
    </CardMessage>
    <article>
        <RouterView/>
    </article>
</template>

<style lang="sass">
@use "assets/colors/colors"
@use "assets/fonts/fonts"

.list-enter-active,
.list-leave-active 
  transition: opacity 0.5s ease

.list-enter-from,
.list-leave-to 
  opacity: 0


html
  min-width: 360px
  @include fonts.regular

  @media screen
    font-size: 10px

  @media print
    font-size: 3.8pt

body
  margin: 0

  @media screen
    color: colors.$secondary
    background: colors.$primary-contrast

  @media print
    color: colors.$print

a
  color: inherit

h1, h2, h3, h4, h5, p
  margin: 0
  font-size: inherit
  @include fonts.regular

th
  @include fonts.regular

.page
  &--hide
    display: none

  &--with-limit
    margin: 0 auto

    @media screen
      padding-top: 4.8em
      padding-left: 5%
      padding-right: 5%
      padding-bottom: 5%
</style>