<template>
    <div class="card-notification">
        <slot></slot>
    </div>
</template>

<style scoped lang="sass">
.card-notification
    position: fixed
    display: flex
    flex-direction: column
    gap: 2em
    right: 5%
    bottom: 3em
    align-items: end
    max-width: 90%
    z-index: 50

</style>