<script lang="ts">
import Navbar from "@/components/navbar/Navbar.vue";

export default {
  name: "NavbarFormula",
  components: {Navbar}
}
</script>

<template>
  <div class="header__menu">
    <Navbar>
      <li class="menu-tabs__item">
        <button class="menu-tabs__button" :class="{ 'menu-tabs__button--active':$route.name === 'index' }" type="button" @click="$router.push('/')">
          {{ $t("submenu.index") }}</button>
      </li>
      <li class="menu-tabs__item">
        <button class="menu-tabs__button" :class="{ 'menu-tabs__button--active':$route.name === 'formula' }" type="button" @click="$router.push('/formula')">
          {{ $t("submenu.formula") }}</button>
      </li>
      <li class="menu-tabs__item">
        <button class="menu-tabs__button" :class="{ 'menu-tabs__button--active':$route.name === 'about' }" type="button" @click="$router.push('/about')">
          {{ $t("submenu.about") }}
        </button>
      </li>
    </Navbar>
  </div>
</template>

<style scoped lang="sass">
@use "../../assets/colors/colors"

.menu-tabs
  &__container
    list-style: none
    margin: 0
    padding: 0

    @media screen
      display: flex

    @media print
      display: none

  &__button
    display: block
    border: none
    padding: 0.95em 1.2em
    border-bottom: solid 1px colors.$secondary
    background: none
    cursor: pointer

    &--active
      @extend .menu-tabs__button
      border-bottom-color: colors.$primary
</style>