<template>
    <button class="button button-highlight" type="submit">
        <slot></slot>
    </button>
</template>

<style scoped lang="sass">
@use "../../assets/colors/colors"
.button
  padding: 0.9ex 2ex
  font-size: 2.4em
  border: none
  border-radius: 4px
  transition: transform 0.1s
  cursor: pointer

  &:active
    transform: scale(0.9)
    transition: transform 0.1s

.button-highlight
  background-color: colors.$primary
  color: colors.$primary-contrast

</style>