<script setup lang="ts">
import IconInfo from "@/components/icons/IconInfo.vue"
import IconAsterisk from "@/components/icons/IconAsterisk.vue"
import IconWarning from "@/components/icons/IconWarning.vue"
import IconRectangle from "../icons/IconRectangle.vue";
import { type PropType, type HTMLAttributes, computed, ref, reactive } from 'vue';
import { vMaska } from 'maska';

const props = defineProps({
    error: {
        type: String,
        required: false
    },
    inputMode: {
        type: Object as PropType<HTMLAttributes['inputmode']>,
        required: false,
    },
    max: {
        type: Number,
        required: false
    },
    min: {
        type: Number,
        required: false
    },
    modelValue: {
        type: String,
        required: false,
        default: ''
    },
    placeholder: {
        type: String,
        required: false,
    },
    step: {
        type: Number,
        required: false,
    },
    label: {
      type: String,
      required: false,
      default: ''
    },
    labelDescription: {
      type: String,
      required: false,
    },
    sufix: {
      type: String,
      required: false,
    },
    required: {
      type: Boolean,
      required: false,
      default: false
    },
    info: {
      type: String,
      required: false
    },
    alignText: {
      type: String,
      required: false,
      default: 'left'
    },
    mask: {
        type: String,
        required: false,
        default: null
    },
    disabled: {
        type: Boolean,
        required: false,
        default: false
    },
    type: {
        type: String,
        required: false,
        default: 'text'
    }
})

const maskOptions = reactive({
    mask: props.mask
})

defineEmits(['update:modelValue'])

const isFocus = ref(false)

</script>

<template>
    <div class="field__separador">
        <label class="field__title">
            {{ label }} 
            <span class="field__subtitulo">{{ labelDescription }}</span>
        </label>
        <div class="field" :class="{ 'field--error': error, 'field--info': info, 'field--focus': isFocus }">
            <div class="field__container">
                <input class="field__input" :class="{ 'field--text__right': alignText=='right'}" :type="type" 
                    :placeholder="placeholder"
                    :step="step"
                    :inputmode="inputMode"
                    :min="min"
                    :max="max"
                    :value="modelValue"
                    :disabled="disabled"
                    v-maska:[maskOptions]
                    @input="$emit(
                        'update:modelValue', 
                        ($event.target as HTMLInputElement).value
                    )"
                    @focus="isFocus = true"
                    @blur="isFocus = false"
                />
                <div class="field__campo">
                    <div class="field__measure">
                        <p class="field__text">{{ sufix }}</p>
                    </div>

                    <div class="field__icons">
                        <div class="field__icons-asterisk" v-if="required">
                            <IconAsterisk class="field__asterisk"/>
                        </div>

                        <div class="field__icons-info">
                            <IconWarning class="field__warning" v-if="error"/>
                            <IconInfo class="field__info" v-else-if="info"/>
                        </div>
                    </div>
                </div>
            </div>
            <p v-if="error" class="field__error">
                <IconRectangle class="field__icon-error"/>
                {{ error }}
            </p>
            <p v-else-if="info" class="field__info-text">
                <IconRectangle class="field__icon-info"/>
                {{ info }}
            </p>
        </div>
    </div>
</template>

<style scoped lang="sass">
@use "../../assets/colors/colors"

.field
  position: relative
  background-color: colors.$secondary-contrast
  border-bottom: 1px solid colors.$secondary

  &--focus
    border-bottom-color: colors.$primary

  &--error
    border-bottom-color: colors.$error
    background-color: colors.$error-constrast
    
    & .field__input
      color: colors.$error

    &.field--focus .field__error
      display: block
      bottom: 4em
      opacity: 1
      transition-property: bottom, opacity
      transition-delay: 1s
      transition-duration: 2s

  &--info
    &.field--focus .field__info-text
      display: block
      bottom: 4em
      opacity: 1
      transition-property: bottom, opacity
      transition-delay: 1s
      transition-duration: 2s

  &__separador
    display: flex
    flex-direction: column
    gap: 0.4em
    flex-grow: 1

  &__title
    color: colors.$secondary
    font-size: 1.6em
    font-weight: 500

  &__subtitulo
    font-size: 0.7em
    padding-left: 0.2em

  &__campo
    display: flex
    align-items: flex-end
    padding-bottom: 0.3em

  &__measure
    display: flex
    align-items: center

  &__number
    font-size: 2em
    font-weight: 500
    padding-right: 0.3em

  &__text
    font-size: 1.4em
    font-weight: 500
    color: colors.$secondary
    padding-bottom: 0.5em

  &__icons
    padding: 0.4em

    &-info
      align-items: center
    
    &-asterisk
      display: flex
      justify-content: flex-end
      position:  absolute
      top: 0.4em
      right: 0.4em

  &__asterisk
    width: 0.7em
    height: 0.7em
    fill: colors.$required

  &__info
    width: 2em
    height: 2em
    fill: colors.$info

  &__warning
    width: 2em
    height: 2em
    fill: colors.$error

  &__container
    display: flex

  &__label
    display: none
    width: auto

  &__input
    display: block
    border: none
    padding: 0.5em 0.3em
    font-size: 2em
    box-sizing: border-box
    width: 100%
    background: none
    color: colors.$secondary
    text-align: left

    &:focus
      outline: none
      color: colors.$primary

  &--text__right
    text-align: right

  &__error
    display: none
    position: absolute
    padding: 0.5em
    bottom: 100%
    right: 0
    opacity: 0
    background-color: colors.$error
    color: colors.$error-constrast
    font-size: 1.2em
    border-radius: 2px
    transition-property: bottom, opacity
    transition-delay: 0.1s
    transition-duration: 1s

  &__icon-error
    position: absolute
    top: 2.2em
    bottom: 0
    right: 0.5em
    fill: colors.$error

  &__info-text
    display: none
    position: absolute
    padding: 0.5em
    bottom: 100%
    right: 0
    opacity: 0
    background-color: colors.$info
    color: colors.$error-constrast
    font-size: 1.2em
    border-radius: 2px
    transition-property: bottom, opacity
    transition-delay: 0.1s
    transition-duration: 1s

  &__icon-info
    position: absolute
    top: 2.2em
    bottom: 0
    right: 0.5em
    fill: colors.$info
</style>