import httpClient from ".";
import type AuthenticatedUser from "@/models/AuthenticatedUser";
import type UserCredentialsInterface from "@/models/UserCredentialsInterface";

export class AuthorizationHttp
{
    static authenticate(userCrendentials: UserCredentialsInterface): Promise<AuthenticatedUser>
    {
        return new Promise(async (resolve, reject) => {
            try{
                const payload = {
                    email: userCrendentials.email,
                    senha: userCrendentials.password,
                }

                const resposta = await httpClient.post(
                    'autenticar',
                    payload
                )

                resposta.status == 200
                    ? resolve({
                        nome: resposta.data.nome,
                        token: resposta.data.token,
                        uuid: resposta.data.uuid
                    })
                    : reject()
            } catch {
                reject()
            }
        })
    }
}