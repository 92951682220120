import { ref } from "vue";
import { defineStore } from "pinia";
import type Menssage from "@/models/Menssage";
import { TypeMenssage } from "@/models/Menssage";

export const useMenssageStore = defineStore('menssage', () => {
    let idsCount = 0
    const menssageList = ref([] as Menssage[])


    function addError(title: string, description: string): void
    {
        addMessage({
            title: title,
            description: description,
            type: TypeMenssage.ERRO,
            id: idsCount++
        })
    }

    function addNotice(title: string, description: string): void
    {
        addMessage({
            title: title,
            description: description,
            type: TypeMenssage.AVISO,
            id: idsCount++
        })
    }

    function addSuccess(title: string, description: string): void
    {
        addMessage({
            title: title,
            description: description,
            type: TypeMenssage.SUCESSO,
            id: idsCount++
        })
    }

    function addMessage(menssage: Menssage){
        menssageList.value.push(menssage)

        setTimeout(() => removMessage(menssage.id), 5000)
    }

    function removMessage(id: number){
        menssageList.value = menssageList.value.filter((item) => item.id != id)
    }

    return { 
        menssageList, 
        addError,
        addMessage,
        addNotice,
        addSuccess,
        removMessage,
    }
})