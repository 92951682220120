<script setup lang="ts">
import ButtonHighlight from '@/components/buttons/ButtonHighlight.vue';
import FieldSurgeon from '@/components/fields/FieldSurgeon.vue';
import FieldPatient from "@/components/fields/FieldPatient.vue";
import FieldYear from '@/components/fields/FieldYear.vue';
import FormFormulaParameters from "@/components/forms/FormFormulaParameters.vue";
import { useRouter } from 'vue-router';
import { useFormulaFormStore } from "@/stores/formulaFormStore"
import { useFormulaResultStore } from "@/stores/formulaResultStore"

import NavbarFormula from "@/components/navbar/NavbarFormula.vue";
import {getResultFromHttpCalculator} from "@/http/calculatorHttp";
import {type FormulaParameters} from "@/models/FormulaParameters";
import {pdfFirstPageToText} from "@/helpers/pdfHelper";
import {getResultFromHttpAutomation} from "@/http/automationHttp";
import {ref} from "vue";
const router = useRouter()
const formulaResultStore = useFormulaResultStore()
const formulaFormStore = useFormulaFormStore()


type AutomationResult = {
  "paciente"?: string,
  "cirurgiao"?: string,
  "idade_paciente"?: number,
  "OD"?: {
    "AL"?: number,
    "ACD"?: number,
    "K1"?: number,
    "K2"?: number,
    "LT"?: number,
    "WTW"?: number,
  },
  "OS"?: {
    "AL"?: number,
    "ACD"?: number,
    "K1"?: number,
    "K2"?: number,
    "LT"?: number,
    "WTW"?: number,
  }
}

const loadingFile = ref<boolean>(false);
const showErrorMessage = ref<boolean>(false);

function changeOdCheck(): void
{
  if(formulaFormStore.formulaForm.od == undefined) {
    formulaFormStore.formulaForm.od = {
      aConstantList: [],
      idLensList: [], eyeParameters: {
        acd: 0,
        al: 0,
        k: {
          k1: {
            k: 0,
            axis: 0
          },
          k2: {
            k: 0,
            axis: 0
          },
          incision: 0
        },
        lt: 0,
        wtw: 0
      }} as FormulaParameters;
  }

  if (formulaFormStore.formulaForm.os?.aConstantList && "aConstantList" in formulaFormStore.formulaForm.od) {
    formulaFormStore.formulaForm.od.idLensList = [...formulaFormStore.formulaForm.os.idLensList]
    formulaFormStore.formulaForm.od.aConstantList = [...formulaFormStore.formulaForm.os.aConstantList]
  }
}

function changeOsCheck(): void
{
  if(formulaFormStore.formulaForm.os == undefined) {
    formulaFormStore.formulaForm.os = {aConstantList: [],
      idLensList: [], eyeParameters: {
        acd: 0,
        al: 0,
        k: {
          k1: {
            k: 0,
            axis: 0
          },
          k2: {
            k: 0,
            axis: 0
          },
          incision: 0
        },
        lt: 0,
        wtw: 0
      }} as FormulaParameters;
  }

  if ("aConstantList" in formulaFormStore.formulaForm.os && formulaFormStore.formulaForm.od?.aConstantList) {
    formulaFormStore.formulaForm.os.idLensList = [...formulaFormStore.formulaForm.od.idLensList]
    formulaFormStore.formulaForm.os.aConstantList = [...formulaFormStore.formulaForm.od.aConstantList]
  }
}

async function eventSubmit()
{
  const formulaForm = {...formulaFormStore.formulaForm};

  let newFormulaParameters = formulaForm.od;
  if (
      !(newFormulaParameters?.aConstantList[0]
      && newFormulaParameters?.aConstantList[0] > 0
      && newFormulaParameters?.idLensList[0]
      && newFormulaParameters?.eyeParameters
      && newFormulaParameters?.eyeParameters.acd > 0
      && newFormulaParameters?.eyeParameters.al > 0
      && newFormulaParameters?.eyeParameters.k.k1.k > 0
      && newFormulaParameters?.eyeParameters.k.k2.k > 0)
  ) {
    formulaForm.od = undefined
  }

  newFormulaParameters = formulaForm.os;
  if (
      !(newFormulaParameters?.aConstantList[0]
          && newFormulaParameters?.aConstantList[0] > 0
          && newFormulaParameters?.idLensList[0]
          && newFormulaParameters?.eyeParameters
          && newFormulaParameters?.eyeParameters.acd > 0
          && newFormulaParameters?.eyeParameters.al > 0
          && newFormulaParameters?.eyeParameters.k.k1.k > 0
          && newFormulaParameters?.eyeParameters.k.k2.k > 0)
  ) {
    formulaForm.os = undefined
  }

  if((formulaForm.od || formulaForm.os) && !formulaFormStore.formulaForm.loading) {
    if (formulaForm.od)
      formulaForm.od.aConstantList = formulaForm.od?.aConstantList.filter(c => c)
    if (formulaForm.os)
      formulaForm.os.aConstantList = formulaForm.os?.aConstantList.filter(c => c)


    formulaFormStore.formulaForm.loading = true;
    try {
      const result = await getResultFromHttpCalculator(formulaForm);
      formulaFormStore.formulaForm.loading = false;
      if (result) {
        formulaResultStore.save(result)
        router.push({'name': 'result'})
      }
    } catch (e) {
      formulaFormStore.formulaForm.loading = false;
    }

  }
}

if (formulaFormStore.data.type === undefined) {
  router.replace("/");
}

const convertFileToBytes = (file: File) => {
  const reader = new FileReader();
  reader.onload = (e) => {
    const arrayBuffer = e.target?.result;
    const bytes = new Uint8Array(arrayBuffer as any);
    pdfFirstPageToText(bytes).then(({textContent, pdfImageUrl}) => {
      if (textContent === "" && pdfImageUrl === "") {
        loadingFile.value = false
        showErrorMessage.value = true
        return
      }
      if (textContent === "") {
        getResultFromImageUrl(pdfImageUrl as string);
        return;
      }
      getResultFromHttpAutomation('text', textContent).then((result: AutomationResult) => {
        formulaFormStore.formulaForm.patient = {name: result.paciente, year: result.idade_paciente}
        formulaFormStore.formulaForm.surgeon = result.cirurgiao

        formulaFormStore.formulaForm.od = {
          aConstantList: formulaFormStore.formulaForm.od ? formulaFormStore.formulaForm.od.aConstantList : [],
          idLensList: formulaFormStore.formulaForm.od ? formulaFormStore.formulaForm.od.idLensList : [],
          eyeParameters: {
              acd: result.OD?.ACD,
              al: result.OD?.AL,
              k: {
                k1: {
                  k: result.OD?.K1,
                  axis: 0
                },
                k2: {
                  k: result.OD?.K2,
                  axis: 0
                },
                incision: 0
              },
              lt: result.OD?.LT,
              wtw: result.OD?.WTW
            }} as FormulaParameters;

        formulaFormStore.formulaForm.os = {
          aConstantList: formulaFormStore.formulaForm.os ? formulaFormStore.formulaForm.os.aConstantList : [],
          idLensList: formulaFormStore.formulaForm.os ? formulaFormStore.formulaForm.os.idLensList : [],
          eyeParameters: {
              acd: result.OS?.ACD,
              al: result.OS?.AL,
              k: {
                k1: {
                  k: result.OS?.K1,
                  axis: 0
                },
                k2: {
                  k: result.OS?.K2,
                  axis: 0
                },
                incision: 0
              },
              lt: result.OS?.LT,
              wtw: result.OS?.WTW
            }} as FormulaParameters;

        loadingFile.value = false;
      }).catch(() => {
        loadingFile.value = false
        showErrorMessage.value = true
      })
    }).catch(() => {
      loadingFile.value = false
      showErrorMessage.value = true
    })
  };
  reader.readAsArrayBuffer(file);
}

const getResultFromImageUrl = (imageUrl: string) => {
  getResultFromHttpAutomation('image', imageUrl as string).then((result: AutomationResult) => {
    formulaFormStore.formulaForm.patient = {name: result.paciente, year: result.idade_paciente}
    formulaFormStore.formulaForm.surgeon = result.cirurgiao

    formulaFormStore.formulaForm.od = {
      aConstantList: formulaFormStore.formulaForm.od ? formulaFormStore.formulaForm.od.aConstantList : [],
      idLensList: formulaFormStore.formulaForm.od ? formulaFormStore.formulaForm.od.idLensList : [],
      eyeParameters: {
        acd: result.OD?.ACD,
        al: result.OD?.AL,
        k: {
          k1: {
            k: result.OD?.K1,
            axis: 0
          },
          k2: {
            k: result.OD?.K2,
            axis: 0
          },
          incision: 0
        },
        lt: result.OD?.LT,
        wtw: result.OD?.WTW
      }} as FormulaParameters;

    formulaFormStore.formulaForm.os = {
      aConstantList: formulaFormStore.formulaForm.os ? formulaFormStore.formulaForm.os.aConstantList : [],
      idLensList: formulaFormStore.formulaForm.os ? formulaFormStore.formulaForm.os.idLensList : [],
      eyeParameters: {
        acd: result.OS?.ACD,
        al: result.OS?.AL,
        k: {
          k1: {
            k: result.OS?.K1,
            axis: 0
          },
          k2: {
            k: result.OS?.K2,
            axis: 0
          },
          incision: 0
        },
        lt: result.OS?.LT,
        wtw: result.OS?.WTW
      }} as FormulaParameters;

    loadingFile.value = false;
  }).catch(() => {
    loadingFile.value = false
    showErrorMessage.value = true
  })
}

const processImage = (file: File) => {
  const reader = new FileReader();
  reader.onloadend = () => {
    const base64String = reader.result;
    getResultFromImageUrl(base64String as string);
  };
  reader.readAsDataURL(file);
};

const handleFileChange = (event: any) => {
  const file = event.target.files[0];
  loadingFile.value = true
  showErrorMessage.value = false
  if (file) {
    if (file.type === 'application/pdf') {
      convertFileToBytes(file);
    } else if (file.type === 'image/jpeg' || file.type === 'image/png') {
      processImage(file);
    }
  } else {
    loadingFile.value = false
  }
}

</script>

<template>
  <div class="page--with-limit">
    <NavbarFormula></NavbarFormula>
    <h1 class="formula-title"><b>{{formulaFormStore.formulaForm.type ? $t("formula.title." + formulaFormStore.formulaForm.type) : ''}}</b></h1>
    <hr />
    <div>
      <label for="arquivo" style="font-size: 1.4em; margin-right: 5px">{{$t("formula.loadFile")}}</label>
      <input id="arquivo" :disabled="loadingFile" type="file" accept="application/pdf,image/jpeg,image/png" @change="handleFileChange" />
      <p v-if="loadingFile">{{$t("formula.loading")}}</p>
      <p class="error-message" v-if="showErrorMessage">{{$t("formula.errorMessage")}}</p>
    </div>
    <form class="form" @submit.prevent="eventSubmit">
      <section class="form-data-basic">
        <div class="form-data-basic__content">
          <div class="form-data-basic__field-surge">
            <FieldSurgeon v-model="formulaFormStore.formulaForm.surgeon"/>
          </div>
          <div class="form-data-basic__field-patient">
            <FieldPatient v-model="formulaFormStore.formulaForm.patient.name"/>
          </div>
          <div class="form-data-basic__field-year">
            <FieldYear :sufix="$t('formula.form.years')" v-model="formulaFormStore.formulaForm.patient.year"/>
          </div>
        </div>
      </section>

      <div class="form-data-parameters">
        <section class="form-data-parameters__od">
          <FormFormulaParameters :title="$t('formula.form.od')" :text="$t('formula.form.od_right')"
                                 :formulaParameters="formulaFormStore.formulaForm.od"
                                 @update:formula-parameters="formulaParameters => formulaFormStore.formulaForm.od = formulaParameters"
                                 :copyMessage="$t('formula.form.od_copy')" :copyValues="changeOdCheck"
          />
        </section>

        <section class="form-data-parameters__os">
          <FormFormulaParameters :title="$t('formula.form.os')" :text="$t('formula.form.os_left')"
                                 :formulaParameters="formulaFormStore.formulaForm.os"
                                 @update:formula-parameters="formulaParameters => formulaFormStore.formulaForm.os = formulaParameters"
                                 :copyMessage="$t('formula.form.os_copy')" :copyValues="changeOsCheck"
          />
        </section>
      </div>

      <div class="formula-form__footer">
        <ButtonHighlight v-if="!formulaFormStore.formulaForm.loading">{{$t("formula.calculate")}}</ButtonHighlight>
        <ButtonHighlight v-if="formulaFormStore.formulaForm.loading">
          {{$t("formula.loading")}}
        </ButtonHighlight>
      </div>
    </form>
  </div>
</template>

<style scoped lang="sass">
@use "../assets/colors/colors"

$break-layout: 1130px

.page--with-limit
  position: relative

.form-data-basic
  &__title
    display: none

  &__content
    display: flex
    flex-direction: column
    gap: 0.6em

  @media (min-width: 700px)
    &__content
      flex-direction: row
      gap: 3.70%

    &__field-surge
      width: 20%

    &__field-patient
      width: 59.6%

    &__field-year
      width: 13%

.form-data-parameters
  padding-top: 5.6rem
  display: flex
  flex-direction: column
  gap: 4em

  @media (min-width: $break-layout)
    flex-direction: row
    gap: 3.2em

  &__od
    color: colors.$tertiary
    flex-grow: 1

  &__os
    color: colors.$quaternary
    flex-grow: 1

.formula-form
  &__footer
    padding-top: 7.71%
    text-align: center
    margin-bottom: 100px

.formula-title
  font-size: 2em

.error-message
  color: colors.$error
</style>
