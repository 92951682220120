<script setup lang="ts">
import { computed, ref } from 'vue';
import Field from './Field.vue';

const props = defineProps({
    modelValue: {
        type: Number,
        required: false,
    }
})
const emits = defineEmits(['update:modelValue'])

const errorMessage = ref<string|undefined>()
const valueFromInput = ref('')
const valueToInput = computed({
    get(): string {
        return errorMessage.value 
            ? valueFromInput.value
            : props.modelValue ? props.modelValue.toString() : ''
    },
    set(newValue: string){
        valueFromInput.value = newValue
    }
})

function emitValue(newValue: string)
{
    const value = parseFloat(newValue.replace(',', '.'))

    if(
        Number.isNaN(value)
        || (value < 0 || value > 120)
    ) {
        errorMessage.value = 'Value invalid'
        valueFromInput.value = newValue
        emits('update:modelValue', 0)
        return
    }

    errorMessage.value = undefined
    emits('update:modelValue', value)
}

</script>

<template>
    <Field placeholder="0"
        :error="errorMessage"
        :modelValue="valueToInput"
        @update:modelValue="emitValue"
    />
</template>