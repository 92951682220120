<script setup lang="ts">
import IconCancel from "@/components/icons/IconCancel.vue"
import ButtonCancel from "../buttons/ButtonCancel.vue";
import { ref } from "vue";
import { useMenssageStore } from "@/stores/menssage";

const store = useMenssageStore()

const prop = defineProps({
    title: {
        type: String,
        required: true
    },
    description: {
        type: String,
        required: true
    },
    id: {
        type: Number,
        required: true
    }
})

const menssageActive = ref(true)

const menssage = function(){
    if(menssageActive.value == true){
        menssageActive.value = false
    }
    return
}

</script>

<template>
    <div class="menssage" v-if="menssageActive">
        <ButtonCancel class="menssage__button" @click="() => store.removMessage(prop.id)">
            <IconCancel class="menssage__icon"/>
        </ButtonCancel>
        <h2 class="menssage__title"> {{ prop.title }} </h2>
        <p class="menssage__description"> {{ prop.description }} </p>
    </div>
</template>

<style scoped lang="sass">
@use "../../assets/colors/colors"

.menssage
    background-color: colors.$menssage-warning
    border-radius: 1em
    padding-left: 3em
    padding-top: 1.5em
    padding-right: 5em
    padding-bottom: 2em
    width: fit-content
    border: 1px solid colors.$menssage-warning
    display: flex
    flex-direction: column
    gap: 0.5em
    position: relative

    &__button
        background-color: colors.$menssage-warning

    &__icon
        fill: colors.$menssage-text
        width: 2.5em
        height: 2em

    &__title
        font-size: 2.4em
        color: colors.$menssage-text

    &__description
        font-size: 1.7em
        color: colors.$menssage-text

</style>