const minValue = 30
const maxValue = 49

interface OcularK {
    axis: number
    k: number
}

interface OcularKSet {
    k1: OcularK
    k2: OcularK
    incision: number
}

export { 
    minValue, 
    maxValue, 
    type OcularK,
    type OcularKSet,
}