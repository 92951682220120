<script setup lang="ts">
import { computed, ref } from 'vue';
import Field from './Field.vue';
import { minValue, maxValue } from '@/models/OcularLt'

const props = defineProps({
    modelValue: {
        type: Number,
        required: false,
    },
    required: {
      type: Boolean,
      required: false,
      default: false
    },
})
const emit = defineEmits(['update:modelValue'])
const valueFromInput = ref('')

const valueToInput = computed({
    get(): string {
        return errorMessage.value 
            ? valueFromInput.value
            : props.modelValue ? props.modelValue.toString() : ''
    },
    set(newValue: string){
        valueFromInput.value = newValue
    }
})
const errorMessage = ref<string|undefined>()

function emitValue(newValue: string)
{
    if(! newValue && ! props.required){
        errorMessage.value = undefined
        emit('update:modelValue', undefined)
        return
    }

    const value = parseFloat(newValue.replace(',', '.'))

    if(
        Number.isNaN(value)
        || (value < minValue || value > maxValue)
    ) {
        errorMessage.value = 'Value invalid'
        valueFromInput.value = newValue
        emit('update:modelValue', 0)
        return
    }

    errorMessage.value = undefined
    emit('update:modelValue', value)
}
</script>

<template>
    <Field 
        alignText="right" 
        info="(2.50 - 8.00)"
        mask="#.##"
        :error="errorMessage"
        :modelValue="valueToInput"
        :required="props.required"
        @update:modelValue="emitValue"
    />
</template>