import axios from "axios";

export const getResultFromHttpAutomation = async (type: string, value: string) => {
    const response = await axios.post(
        import.meta.env.VITE_AUTOMATION_BASE_URL,
        {type, value},
        {
            headers: {
                "Authorization": 'Basic ' + import.meta.env.VITE_AUTOMATION_AUTH_TOKEN
            }
        } as any
    )
    if (response.status === 200) {
        return JSON.parse(response.data.body.replace("```json\n", "").replace("\n```", ""));
    }
    return undefined;
}