import type { CalculatorResult, CalculatorResultData } from "@/models/CalculatorResult";
import type { ExameOcularApiInterface, ExameOcularOlhoApiInterface } from "@/models/ExameOcularApiInterface";
import httpClient from ".";

export class EyeExamHttp
{
    static store(calculatorResult: CalculatorResult): Promise<boolean>
    {
        return new Promise(async(resolve, reject) => {
            try {
                const eyeLeft =  calculatorResult.os.length > 0
                    ? transformCalculatorResultDataToExameOcularOlho(calculatorResult.os)
                    : null

                const eyeRight = calculatorResult.od.length > 0
                    ? transformCalculatorResultDataToExameOcularOlho(calculatorResult.od)
                    : null

                const payload: ExameOcularApiInterface = {
                    uuid: calculatorResult.uuid,
                    pacienteIdade: calculatorResult.patient.year ?? null,
                    pacienteNome: calculatorResult.patient.name ?? null,
                    cirurgiaoNome: calculatorResult.surgeon ?? null,
                    olhoEsquerdo: eyeLeft,
                    olhoDireito: eyeRight
                }

                const resposta = await httpClient.post(
                    'exames-oculares',
                    payload
                )
    
                resposta.status == 201
                    ? resolve(true)
                    : reject()
            } catch {
                reject()
            }
        })
    }
}

function transformCalculatorResultDataToExameOcularOlho(calculatorResultDataList: CalculatorResultData[]): ExameOcularOlhoApiInterface
{
    return {
        parametros: {
            al: calculatorResultDataList[0].eyeParameters.al,
            acd: calculatorResultDataList[0].eyeParameters.acd,
            k1: calculatorResultDataList[0].eyeParameters.k.k1.k,
            k1Axis: nonZeroOrNull(calculatorResultDataList[0].eyeParameters.k.k1.axis),
            k2: calculatorResultDataList[0].eyeParameters.k.k2.k,
            k2Axis: nonZeroOrNull(calculatorResultDataList[0].eyeParameters.k.k2.axis),
            lt: calculatorResultDataList[0].eyeParameters.lt,
            wtw: nonZeroOrNull(calculatorResultDataList[0].eyeParameters.wtw),
            incision: nonZeroOrNull(calculatorResultDataList[0].eyeParameters.k.incision)
        },
        resultadosEstimados: calculatorResultDataList.map(calculatorResultData => {
            return {
                versao: 1,
                aConstant: calculatorResultData.aConstant,
                target: calculatorResultData.target,
                iolPower: calculatorResultData.iolPower,
                se: calculatorResultData.se
            }
        })
    }
}

function nonZeroOrNull(value: number): number|null
{
    return value != 0
        ? value
        : null
}