import { computed, ref, type ComputedRef, type Ref } from 'vue'
import { defineStore } from 'pinia'
import { type FormulaFormData } from '@/models/FormulaFormData'

const useFormulaFormStore = defineStore('formulaFormStore', () => 
{
    const formulaFormDataEmpty = {
        type: undefined,
        surgeon: undefined,
        patient: {
            name: undefined,
            year: undefined
        },
        od: undefined,
        os: undefined,
        loading: false
    }

    const data: Ref<FormulaFormData> = ref(formulaFormDataEmpty)

    const formulaForm: ComputedRef<FormulaFormData> = computed(() => data.value)

    function save(formulaFormData: FormulaFormData): void
    {
        data.value = formulaFormData
    }

    function clear(): void
    {
        data.value.surgeon = undefined
        data.value.patient.name = undefined
        data.value.patient.year = undefined
        data.value.od = undefined
        data.value.os = undefined
        data.value.loading = false
    }

    return {
        save,
        clear,
        formulaForm,
        data
    }
})

export {
    useFormulaFormStore,
}