<script setup lang="ts">
import CardResult from '@/components/cards/CardResult.vue';
import ButtonSimple from '@/components/buttons/ButtonSimple.vue';
import { useFormulaResultStore } from '@/stores/formulaResultStore';
import { useFormulaFormStore } from '@/stores/formulaFormStore';
import { computed, ref } from 'vue';
import { monthName } from '@/helpers/dateHelper'
import router from '@/router';
import Login from '@/components/layouts/Login.vue';
import { useAuthenticatedUserStore } from '@/stores/authenticatedUserStore';
import { EyeExamHttp } from '@/http/eyeExamHttp'
import { useMenssageStore } from '@/stores/menssage';
import NavbarFormula from "@/components/navbar/NavbarFormula.vue";
import {useI18n} from "vue-i18n";
import { jsPDF } from "jspdf";

const authenticatedUserStore = useAuthenticatedUserStore()
const formulaFormStore = useFormulaFormStore()
const formulaResultStore = useFormulaResultStore()
const messageStore = useMenssageStore()

const result = formulaResultStore.result
const loginWindowActive = ref(false)

if (formulaFormStore.data.type === undefined) {
  router.replace("/");
}

const i18n = useI18n();

const dateTime = computed(() => {
    const month = monthName(result?.dateTime.getMonth() ?? 13, i18n.locale.value)
    const day = result?.dateTime.getDate()
    const year = result?.dateTime.getFullYear()
    const hour = result?.dateTime.getHours()
    const minutes = result?.dateTime.getMinutes()

    if (i18n.locale.value === 'pt-br')
      return `${day} de ${month} de ${year} às ${hour}:${minutes}`
    return `${month} ${day}, ${year} at ${hour}:${minutes}`
})

function print(): void
{
  const doc = new jsPDF('p', 'mm', 'a4');

  const content = document.getElementById('card-result-print');
  const contentClone: any = content?.cloneNode(true);

  const title = document.querySelector('.formula-title')?.cloneNode(true);

  if (title) {
    contentClone.insertBefore(title, contentClone.firstChild);
  }

  const header = document.querySelector('.header__identification')?.cloneNode(true);

  if (header) {
    contentClone.insertBefore(header, contentClone.firstChild);
  }

  const resultElement = contentClone.querySelector('.calculation-result__result');
  if (resultElement) {
    resultElement.style.paddingTop = '0';
    resultElement.style.display = 'flex';
    resultElement.style.flexDirection = 'row';
  }

  const resultItemElements = contentClone.querySelectorAll('.result-lens__item:nth-child(even)');
  if (resultItemElements) {
    resultItemElements.forEach((elem: any) => {
      elem.style.maxWidth = '50%';
    })
  }

  const parametersElements = contentClone.querySelectorAll('.parameters');
  if (parametersElements) {
    parametersElements.forEach((elem: any) => {
      elem.style.flexDirection = 'row';
      elem.style.gap = 'None';
    })
  }

  const parametersRowElements = contentClone.querySelectorAll('.parameters-row');
  if (parametersRowElements) {
    parametersRowElements.forEach((elem: any) => {
      elem.style.display = 'block';
      elem.style.gap = 'None';
      elem.style.baseline = 'None';
    })
  }

  const parametersRowSpanElements = contentClone.querySelectorAll('.parameters-row span');
  if (parametersRowSpanElements) {
    parametersRowSpanElements.forEach((elem: any) => {
      elem.style.width = '100%';
      elem.style.textAlign = 'left';
      elem.style.paddingLeft = '5px';
    })
  }

  const button = contentClone.querySelector("#calculation-result-buttons");
  if (button) {
    button.style.display = 'none';
  }

  doc.html(contentClone, {
    callback: function (doc) {
      doc.save('resultado-lucena.pdf');
    },
    x: 10,
    y: 10,
    width: 180,
    windowWidth: 1280,
    html2canvas: {
      scale: 0.15,
    },
  });

}

function newFormula(): void
{
    formulaFormStore.formulaForm.surgeon = undefined
    formulaFormStore.formulaForm.patient.name = undefined
    formulaFormStore.formulaForm.patient.year = 0
    formulaFormStore.formulaForm.od = undefined
    formulaFormStore.formulaForm.os = undefined
    formulaResultStore.isSaved = false
    router.push('/')
}

function save(): void
{
    if (! authenticatedUserStore.authenticatedUser) {
        loginWindowActive.value = true
        return
    }

    loginWindowActive.value = false

    if (! result) {
        return
    }

    EyeExamHttp.store(result).then(() => {
        formulaResultStore.isSaved = true
        messageStore.addSuccess(
            'Result saved successfully',
            'Access the management panel to add more information'
        )
    }).catch(() => {
        messageStore.addError(
            'Unable to save formula result',
            'Please inform us via email contato@ciebit.com'
        )
    })
}
</script>

<template>
    <Login 
        v-if="loginWindowActive"
        @cancelar="loginWindowActive = false"
        @authenticated="save"/>

    <div class="page--with-limit page--show">
      <NavbarFormula></NavbarFormula>
      <h1 class="formula-title"><b>{{formulaFormStore.data.type ? $t('result.title.' + formulaFormStore.data.type) : ''}}</b></h1>
      <hr />
        <div v-if="formulaResultStore.result" id="card-result-print" class="calculation-result">
            <div class="calculation-result__date">
                <p>{{ dateTime }}</p>
            </div>
            <div class="calculation-result__data-basic">
                <p class="text-topic-value">
                    <span class="text-topic-value__topic">{{$t('result.form.patient')}}:</span>
                    <span class="text-topic-value__value">{{ formulaResultStore.result.patient.name }}</span>
                </p>

                <p class="text-topic-value">
                    <span class="text-topic-value__topic">{{$t('result.form.surgeon')}}:</span>
                    <span class="text-topic-value__value">{{ formulaResultStore.result.surgeon }}</span>
                </p>
            </div>

            <div class="calculation-result__result">
                <div class="calculation-result__eye calculation-result__result-od">
                  <CardResult :title="$t('formula.form.od')" :text="$t('result.form.od_right')" :calculatorResultData="formulaResultStore.result.od"/>
                </div>

                <div class="calculation-result__eye calculation-result__result-os">
                  <CardResult :title="$t('formula.form.os')" :text="$t('result.form.os_left')" :calculatorResultData="formulaResultStore.result.os"/>
                </div> 
            </div>

            <div class="calculation-result__buttons" id="calculation-result-buttons">
                <ButtonSimple @click="print">{{ $t('result.actions.print') }}</ButtonSimple>
                <ButtonSimple @click="newFormula">{{ $t('result.actions.new') }}</ButtonSimple>
                <ButtonSimple @click="$router.push('/formula')">{{ $t('result.actions.edit') }}</ButtonSimple>
                <ButtonSimple @click="save" v-if="! formulaResultStore.isSaved">{{ $t('result.actions.save') }}</ButtonSimple>
            </div>
        </div>
        <p v-else>No result</p>
    </div>
</template>

<style scoped lang="sass">
@use "../assets/colors/colors"
@use "../assets/fonts/fonts"

.page--with-limit
  position: relative
.calculation-result
  &__data-basic
    display: flex
    font-size: 2em
    flex-direction: column
    gap: 1em

    @media print
      padding-top: 2em

    @media print, (min-width: 860px)
      flex-direction: row
      gap: 3em

    & .text-topic-value__topic
      @include fonts.medium
      padding-right: 0.5em

  &__date
    font-size: 1.4em
    color: colors.$secondary
    padding-bottom: 3.4em

    @media print
        padding-bottom: 0

  &__result
    display: flex
    flex-direction: column
    gap: 3em

    @media screen
      padding-top: 5.7em

    @media print
      padding-top: 2em

    @media print, (min-width: 860px)
      flex-direction: row
      gap: 3.68%

    &-od
      @media screen
        color: colors.$tertiary

    &-os
      @media screen
        color: colors.$quaternary

  &__eye
    flex-grow: 1
    width: 100%

  &__buttons
    flex-wrap: wrap
    gap: 2.40%
    padding-top: 7.06%
    justify-content: center
    margin-bottom: 100px

    @media screen
      display: flex

    @media print
      display: none

.formula-title
  font-size: 2em

  @media print
    margin-top: 20px

</style>