<template>
    <nav class="menu-tabs">
        <ul class="menu-tabs__container">
          <slot></slot>
        </ul>
    </nav>
</template>

<style scoped lang="sass">
@use "../../assets/colors/colors"

.menu-tabs
  position: absolute
  right: 0
  top: 0
  &__container
    list-style: none
    margin: 0
    padding: 0

    @media screen
      display: flex

    @media print
      display: none

  &__button
    display: block
    border: none
    padding: 0.95em 1.2em
    border-bottom: solid 1px colors.$secondary
    background: none
    cursor: pointer

    &--active
      @extend .menu-tabs__button
      border-bottom-color: colors.$primary
</style>