<script setup lang="ts">
import FormLens from "@/components/forms/FormLens.vue"
import FromEyeParameters from "@/components/forms/FromEyeParameters.vue"
import CardTitleWithAbbreviation from "@/components/cards/CardTitleWithAbbreviation.vue"
import Card from "@/components/cards/Card.vue";
import type { EyeParameters } from "@/models/EyeParameters";
import { reactive, watch, type PropType } from 'vue';
import type { FormulaParameters } from "@/models/FormulaParameters";

const props = defineProps({
    title : {
        type: String,
        required: true
    },
    text : {
        type: String,
        required: true
    },
    formulaParameters: {
        type: Object as PropType<FormulaParameters>,
        required: false
    },
    copyMessage : {
      type: String,
      required: true
    },
    copyValues: {
      type: Function as any,
      required: false
    }
})
const emits = defineEmits(['update:formula-parameters'])

const formulaParameters = reactive({
    aConstantList: [
        props.formulaParameters?.aConstantList[0] ?? 0,
        props.formulaParameters?.aConstantList[1] ?? 0,
        props.formulaParameters?.aConstantList[2] ?? 0,
        props.formulaParameters?.aConstantList[3] ?? 0,
    ],
    idLensList: [
      props.formulaParameters?.idLensList[0] ?? 0,
      props.formulaParameters?.idLensList[1] ?? 0,
      props.formulaParameters?.idLensList[2] ?? 0,
      props.formulaParameters?.idLensList[3] ?? 0,
    ],
    eyeParameters: {
        acd: props.formulaParameters?.eyeParameters.acd ?? 0,
        al: props.formulaParameters?.eyeParameters.al ?? 0,
        k: {
            k1: {
                k: props.formulaParameters?.eyeParameters.k.k1.k ?? 0,
                axis: props.formulaParameters?.eyeParameters.k.k1.axis ?? 0
            },
            k2: {
                k: props.formulaParameters?.eyeParameters.k.k2.k ?? 0,
                axis: props.formulaParameters?.eyeParameters.k.k2.axis ?? 0
            },
            incision: props.formulaParameters?.eyeParameters.k.incision ?? 0
        },
        lt: props.formulaParameters?.eyeParameters.lt ?? 0,
        wtw: props.formulaParameters?.eyeParameters.wtw ?? 0
    } as EyeParameters
})

watch(props, (newValue) => {
    if(! newValue.formulaParameters) {
        return
    }
    formulaParameters.aConstantList[0] = newValue.formulaParameters.aConstantList[0]
    formulaParameters.aConstantList[1] = newValue.formulaParameters.aConstantList[1]
    formulaParameters.aConstantList[2] = newValue.formulaParameters.aConstantList[2]
    formulaParameters.aConstantList[3] = newValue.formulaParameters.aConstantList[3]
    formulaParameters.idLensList[0] = newValue.formulaParameters.idLensList[0]
    formulaParameters.idLensList[1] = newValue.formulaParameters.idLensList[1]
    formulaParameters.idLensList[2] = newValue.formulaParameters.idLensList[2]
    formulaParameters.idLensList[3] = newValue.formulaParameters.idLensList[3]
    formulaParameters.eyeParameters.acd = newValue.formulaParameters.eyeParameters.acd
    formulaParameters.eyeParameters.al = newValue.formulaParameters.eyeParameters.al
    formulaParameters.eyeParameters.k.k1.k = newValue.formulaParameters.eyeParameters.k.k1.k
    formulaParameters.eyeParameters.k.k1.axis = newValue.formulaParameters.eyeParameters.k.k1.axis
    formulaParameters.eyeParameters.k.k2.k = newValue.formulaParameters.eyeParameters.k.k2.k
    formulaParameters.eyeParameters.k.k2.axis = newValue.formulaParameters.eyeParameters.k.k2.axis
    formulaParameters.eyeParameters.k.incision = newValue.formulaParameters.eyeParameters.k.incision
    formulaParameters.eyeParameters.lt = newValue.formulaParameters.eyeParameters.lt
    formulaParameters.eyeParameters.wtw = newValue.formulaParameters.eyeParameters.wtw
})

watch(formulaParameters, (newFormulaParameters) => {
    emits("update:formula-parameters", newFormulaParameters)
})
</script>

<template>
  <Card>
    <CardTitleWithAbbreviation :title="title" :text="text"/>

    <div class="cards">
        <div class="cards__container">
            <FormLens class="card_lens" title="Lens 1" 
            :required="true" 
            :aConstant="formulaParameters.aConstantList[0]"
            :idLens="formulaParameters.idLensList[0]"
            @update:a-constant="aConstant => formulaParameters.aConstantList[0] = aConstant"
            @update:id-lens="idLens => formulaParameters.idLensList[0] = idLens"
            />
        
            <FormLens class="card_lens" title="Lens 2"
                      :aConstant="formulaParameters.aConstantList[1]"
                      :idLens="formulaParameters.idLensList[1]"
                      @update:a-constant="aConstant => formulaParameters.aConstantList[1] = aConstant"
                      @update:id-lens="idLens => formulaParameters.idLensList[1] = idLens"
            />
        </div>

        <div class="cards__container">
            <FormLens class="card_lens" title="Lens 3"
                      :aConstant="formulaParameters.aConstantList[2]"
                      :idLens="formulaParameters.idLensList[2]"
                      @update:a-constant="aConstant => formulaParameters.aConstantList[2] = aConstant"
                      @update:id-lens="idLens => formulaParameters.idLensList[2] = idLens"
            />

            <FormLens class="card_lens" title="Lens 4"
                      :aConstant="formulaParameters.aConstantList[3]"
                      :idLens="formulaParameters.idLensList[3]"
                      @update:a-constant="aConstant => formulaParameters.aConstantList[3] = aConstant"
                      @update:id-lens="idLens => formulaParameters.idLensList[3] = idLens"
            />
        </div>
        <a class="field-link-check" v-if="copyMessage && copyValues" @click="copyValues">{{copyMessage}}</a>
    </div>

    <FromEyeParameters
        :eyeParameters="formulaParameters.eyeParameters"
        @update:eye-parameters="eyeParameters => formulaParameters.eyeParameters = eyeParameters"/>

  </Card>
</template>

<style scoped lang="sass">
@use "../../assets/colors/colors"
@use "../../assets/fonts/fonts"

.cards
  display: flex
  flex-direction: column
  gap: 0.4em
  padding-left: 2em
  padding-right: 2em
  padding-top: 3.5em

  &__container
    display: flex
    gap: 0.4em

    @media (max-width: 570px)
      flex-direction: column

.card_lens
  flex-basis: 50%

.field-link-check
  color: colors.$secondary
  text-decoration: underline
  font-size: 1.3em
  cursor: pointer

</style>