<script setup lang="ts">
import {computed, ref, watch} from 'vue';
import Field from './Field.vue'
import {OcularAConstant} from "@/models/OcularAConstant"

const props = defineProps({
    required: {
      type: Boolean
    },
    modelValue: {
        type: Number,
        required: false
    }
})
const emit = defineEmits(['change:aConstant'])

const error = ref(false)
const errorMessage = computed(() => error.value ? 'Value invalid' : undefined)
const valueToExternal = ref<number|undefined>()

const aConstant = ref<string|undefined>(props.modelValue ? props.modelValue?.toString() : '')

function emitValue(newValue: string): void
{
    valueToExternal.value = parseFloat(newValue.replace(',', '.'))

    if(OcularAConstant.validate(Number(valueToExternal.value))) {
        error.value = false
    } else {
      if (newValue !== '' || props.required)
        error.value = true
    }
    aConstant.value = newValue

    emit('change:aConstant', valueToExternal.value)
}

watch(props, (newValue) => {
  if(newValue.modelValue) {
    aConstant.value = newValue.modelValue.toString()
  } else {
    aConstant.value = ''
  }
})

</script>

<template>
    <Field inputmode="decimal" alignText="right"
        :error="errorMessage"
        :modelValue="aConstant"
        :min="OcularAConstant.minValue"
        :max="OcularAConstant.maxValue"
        :step="0.0001"
        @update:modelValue="emitValue"
    />
</template>