import {PDFDocument} from 'pdf-lib';


export async function pdfFirstPageToText(pdfBytes: any): Promise<{ textContent: string, pdfImageUrl: string }> {
    const pdfjs = await import('pdfjs-dist/legacy/build/pdf');
    // @ts-ignore
    pdfjs.GlobalWorkerOptions.workerSrc = await import('pdfjs-dist/legacy/build/pdf.worker.entry');

    const pdfDoc = await PDFDocument.load(pdfBytes);

    // Verificar se há páginas no PDF
    if (pdfDoc.getPageCount() > 0) {
        // Criar um novo documento PDF
        const newPdfDoc = await PDFDocument.create();

        // Copiar a primeira página do documento original para o novo documento
        const [firstPage] = await newPdfDoc.copyPages(pdfDoc, [0]);
        newPdfDoc.addPage(firstPage);

        // Serializar o novo documento para bytes
        const pdfBytes = await newPdfDoc.save();

        const pdf = await pdfjs.getDocument({ data: pdfBytes }).promise;
        const page = await pdf.getPage(1);
        const viewport = page.getViewport({ scale: 1 });
        const canvas = document.createElement('canvas');
        const context = canvas.getContext('2d');
        canvas.height = viewport.height;
        canvas.width = viewport.width;

        await page.render({ canvasContext: context as any, viewport: viewport }).promise;
        const pdfImageUrl = canvas.toDataURL();

        // Extract text from the PDF using pdfjs-dist
        const loadingTask = pdfjs.getDocument({data: pdfBytes});
        return await loadingTask.promise.then(async (pdf) => {
            let textContent = '';
            for (let i = 0; i < pdf.numPages; i++) {
                const page = await pdf.getPage(i + 1);
                const textContentPage = await page.getTextContent();
                textContentPage.items.forEach((item: any) => {
                    textContent += item.str + ' ';
                });
            }
            return {textContent, pdfImageUrl};
        });
    }

    return { textContent: '', pdfImageUrl: '' };
}