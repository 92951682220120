import axios from "axios";
import {v4 as uuidV4} from "uuid";
import { type FormulaFormData } from '@/models/FormulaFormData'


export const getResultFromHttpCalculator = async (formData: FormulaFormData) => {
    const response = await axios.post(
        import.meta.env.VITE_BASE_URL,
        formData,
        {
            headers: {
                "X-Require-Whisk-Auth": import.meta.env.VITE_AUTH_TOKEN
            }
        } as any
    )
    if (response.status === 200) {
        let result: any = response.data;
        result.uuid = uuidV4();
        result.dateTime = new Date(result.dateTime);
        return result;
    }
    return undefined;
}