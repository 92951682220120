<template>
    <div class="people-card">
        <figure class="people-card__photo-container">
          <img class="people-card__photo" src="/assets/images/abrahao.png" width="103" height="103" alt="Abrahão Lucena"/>
        </figure>
        <div class="people-card__text-container">
          <h1 class="people-card__name">Abrahão Lucena, MD, M.Sc, Ph.D</h1>
          <p class="people-card__mail"><a class="people-card__mail-link" href="mailto:abrahaolucena@icloud.com">abrahaolucena@icloud.com</a></p>
          <p class="people-card__country">Brasil</p>
        </div>
    </div>
</template>

<style scoped lang="sass">
.people-card
  display: flex
  gap: 2em
  align-items: center

  &__photo-container
    margin: 0

  &__name
    font-size: 1.6em

  &__mail,
  &__contry
    font-size: 1.4em

  &__mail-link
    text-decoration: none
</style>