<script setup lang="ts">
import type { CalculatorResultData } from '@/models/CalculatorResult';
import type { ComputedRef, PropType } from 'vue';
import { computed } from 'vue';

type LinesRow = {
    target: number,
    iolPower: number,
    se: number
}

const props = defineProps({
    resultData: {
        type: Object as PropType<CalculatorResultData>,
        required: true
    }
})

const totalPreviusRows = 2
const totalNextRows = 4
const data: ComputedRef<LinesRow[]> = computed(() => {
    const dataList = []

    for(let i = totalPreviusRows; i > 0; i--) {
        dataList.push({
            target: 0,
            iolPower: props.resultData?.iolPower + -0.5 * i,
            se: props.resultData?.variationsMinimumResults[i-1]
        })
    }

    dataList.push({
        target: props.resultData?.target,
        iolPower: props.resultData?.iolPower,
        se: props.resultData?.se
    })

    for(let i = 1; i <= totalNextRows; i++) {
        dataList.push({
            target: 0,
            iolPower: props.resultData?.iolPower + 0.5 * i,
            se: props.resultData?.variationMaximumResults[i-1]
        })
    }

    return dataList
})




</script>

<template>
    <div class="result-lens__result-container">
        <table class="table-result-lens">
            <thead class="table-result-lens__header">
                <tr>
<!--                    <th class="table-result-lens__cell">Target</th>-->
                    <th class="table-result-lens__cell">IOL Power</th>
                    <th class="table-result-lens__cell">SE</th>
                </tr>
            </thead>
            <tbody class="table-result-lens__content">
                <tr class="table-result-lens-row" v-for="(row, index) in data" :class="{'table-result-lens-row--focus': row.target > 0}" :key="index">
<!--                    <td class="table-result-lens__cell table-result-lens-row__target">-->
<!--                        <span class="text-inline">{{ row.target == 0 ? '' : row.target.toFixed(2) }}</span>-->
<!--                    </td>-->

                    <td class="table-result-lens__cell table-result-lens-row__iol-power">
                        <span class="text-inline">{{ row.iolPower.toFixed(1) }}</span>
                    </td>

                    <td class="table-result-lens__cell table-result-lens-row__se">
                        <span class="text-inline">{{ row.se.toFixed(2) }}</span>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<style scoped lang="sass">
@use "../../assets/colors/colors"
@use "../../assets/fonts/fonts"

.table-result-lens
  border-collapse: collapse
  width: 100%

  &__header
    text-align: center
    font-size: 1.4em
    @include fonts.regular

  &__content
    text-align: center
    font-size: 1.8em

  &__cell
    font-size: 1.2em
    border-left: 1px solid colors.$secondary

    @media print
      font-size: 1.3em

    &:first-child
      border-left: none

.table-result-lens-row
  color: inherit

  @media print
    font-size: 1.2em

  &--focus
    font-size: 1.1em

    @media print
      font-size: 1.3em
    @include fonts.medium

    & .table-result-lens-row__target,
    & .table-result-lens-row__iol-power,
    & .table-result-lens-row__se
      background-color: colors.$secondary-light

    & .table-result-lens-row__target
      border-top-left-radius: 0.4em
      border-bottom-left-radius: 0.4em


</style>