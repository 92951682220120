<script setup lang="ts">
import FieldSelect from './FieldSelect.vue';
import type ValuesSelectField from '@/models/ValuesSelectField';
import { lensModelCollection } from '@/stores/lensModelCollection';
import type LensModel from '@/models/LensModel';
import {ref, watch} from "vue";


const props = defineProps({
    value: {
        type: Number,
        required: false
    },
    modelValue: {
        type: Number,
        required: false
    },
})
const emits = defineEmits(['change:idLens'])

const idLen = ref<number|undefined>(props.modelValue)

const options: ValuesSelectField[] = [
    {
        'id': 0,
        'title': 'Custom',
        'value': 'no-lens'
    }
]
options.push(
    ...lensModelCollection.sort(
        (lensA: LensModel, lensB: LensModel) => lensA.name > lensB.name ? 1 : -1
    ).map(
        (lens): ValuesSelectField => {
            return {
                'id': lens.id,
                'title': lens.name,
                'value': lens.number.toString(),
            }
        }
    )
);

watch(props, (newValue) => {
  if(newValue.modelValue != undefined) {
    idLen.value = newValue.modelValue
  }
})

watch(idLen, (newValue: any) => {
    emits ('change:idLens', newValue)
})

</script>

<template>
    <FieldSelect 
        :values="options" 
        :modelValue="idLen"
        @update:modelValue="newValue => idLen = newValue"
    />
</template>